import withI18n from 'hocs/with-i18n'
import withAdminStore from 'hocs/with-admin-store'
import withHomePageLayout from 'hocs/with-home-page-layout'
import Container from 'containers/home-page/views'
import withParticipantAccess from 'hocs/with-participant-access'
import withBasicAuth from 'hocs/with-basic-auth'
import withVisitsLogging from 'hocs/with-visits-logging'

const App = () => {
  return (
    <Container />
  )
}

const locales = [
  'common',
  'navigation',
  'admin-layout',
  'editor'
]
const PageWithVisitsLogging = withVisitsLogging(App)
const PageWithBasicAuth = withBasicAuth(PageWithVisitsLogging)
const PageWithLayoutAndI18n = withI18n(withHomePageLayout(PageWithBasicAuth), locales)
const PageWithParticipantAccess = withParticipantAccess(PageWithLayoutAndI18n)
const PageWithRedux = withAdminStore(PageWithParticipantAccess)

export default PageWithRedux
