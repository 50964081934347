import { fetchSite } from 'actions/site';
import { fetchUser } from 'actions/user';
import auth from 'basic-auth';
import { NextComponentType, NextPageContext } from 'next';
import { Component } from 'react';
import compare from 'tsscmp';

interface PageContext extends NextPageContext {
  reduxStore: any;
}

const withBasicAuth = (App: NextComponentType) =>
  class WithBasicAuth extends Component {
    static async getInitialProps(ctx: PageContext) {
      // Fetch current site if not present
      const { currentSite } = ctx.reduxStore.getState();

      // Fetch current user if not present
      const isCurrentSitePresent =
        currentSite.doneFetching && !currentSite.fetchError;
      if (!isCurrentSitePresent) {
        // @ts-ignore
        await ctx.reduxStore.dispatch(fetchSite(''));
      }

      // Fetch current user if not present
      const { currentUser } = ctx.reduxStore.getState();

      const isCurrentUserPresent =
        currentUser.doneFetching && !currentUser.fetchError;

      if (!isCurrentUserPresent) {
        await ctx.reduxStore.dispatch(fetchUser('current'));
      }

      const isSiteOnboardingEnabled =
        Object.prototype.hasOwnProperty.call(
          ctx.reduxStore.getState().features.features,
          'enable_site_onboarding',
        ) &&
        ctx.reduxStore.getState().features.features['enable_site_onboarding']
          .enabled;

      const { blocked, blockUsername, blockPassword } =
        ctx.reduxStore.getState().currentSite.site;

      if (blocked && !isSiteOnboardingEnabled) {
        const userCredentialsFromBasicAuth = auth(ctx.req);

        if (
          compare(userCredentialsFromBasicAuth?.name, blockUsername) &&
          compare(userCredentialsFromBasicAuth?.pass, blockPassword)
        ) {
          ctx.res.statusCode = 200;
        } else {
          ctx.res.statusCode = 401;
          ctx.res.setHeader('WWW-Authenticate', 'Basic realm=protected');
          ctx.res.end('401 Access Denied');
        }
      }

      let appProps = {};

      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps(ctx);
      }

      return {
        ...appProps,
      };
    }

    render() {
      return <App {...this.props} />;
    }
  };

export default withBasicAuth;
