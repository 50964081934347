const isEmpty = (value) => {
  return value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (Array.isArray(value) && value.length === 0)
}

class Query {
  constructor (query = {}, filters = {}) {
    for (const key in query) {
      let value = query[key]
      if (!isEmpty(value)) {
        this[key] = query[key]
      }
    }

    for (const key in filters) {
      let value = filters[key]
      if (!isEmpty(value)) {
        if (!this.filters) {
          this.filters = {}
        }
        this.filters[key] = value
      }
    }
  }
}

export default Query
