import Model from '@itsfadnis/jsonapi-client'

class HomePageRevision extends Model {
  static _type = 'home-page-revisions';

  static baseURL = '/home_page_revisions';

  constructor (args = {}) {
    super(args)

    Model.call(this, args)

    this.sections = args.sections || {}
    this.updatedAt = args.updatedAt
    this.oldHomePageId = args.oldHomePageId
    this.published = typeof args.published === 'boolean' ? args.published : true
  }
}

export default HomePageRevision
